import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { StaggeredAnimationDirective } from '@shared/directives/staggered-animation.directive';
import { TypographyDirective } from '@shared/directives/typography.directive';
import { i18nAttribute } from '@shared/helpers';
import { ClickMenuItemComponent } from '../click-menu-item/click-menu-item.component';
import { CLICK_MENU_DEFAULT_CONFIGURATION, ClickMenuConfiguration, ClickMenuItemGroup } from '../click-menu.types';

@Component({
  selector: 'coach-grouped-click-menu',
  imports: [ClickMenuItemComponent, StaggeredAnimationDirective, TranslocoDirective, TypographyDirective],
  templateUrl: './grouped-click-menu.component.html',
  styleUrls: ['./grouped-click-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedClickMenuComponent {
  items = input.required<ClickMenuItemGroup[]>();
  config = input<ClickMenuConfiguration, ClickMenuConfiguration>(CLICK_MENU_DEFAULT_CONFIGURATION, {
    transform: (config: ClickMenuConfiguration) => ({ ...CLICK_MENU_DEFAULT_CONFIGURATION, ...config }),
  });

  groups = computed(() =>
    this.items().map(group => ({
      ...group,
      title: i18nAttribute(group.title),
    }))
  );
}
