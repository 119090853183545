@if (item(); as i) {
  @if (item().type !== 'info') {
    <button class="menu-item button" (click)="i.action?.()">
      <ng-container *ngTemplateOutlet="content; context: { $implicit: i }" />
    </button>
  } @else {
    <div class="menu-item info">
      <ng-container *ngTemplateOutlet="content; context: { $implicit: i }" />
    </div>
  }
}

<ng-template #content let-i>
  @if (i.image) {
    <img class="image" [src]="i.image" alt="Image for the menu item." />
  }
  @if (i.type === 'info' && i.icon) {
    <coach-icon [icon]="i.icon!" [size]="i.iconSize ?? 'm'" />
  }
  @if (i.type === 'user') {
    <coach-avatar [name]="i.title" [id]="i.id" />
  }
  <div class="wrapper" *transloco="let t">
    <div class="title-container" [class]="'icon-' + i.iconPosition">
      @if (i.type !== 'info' && i.icon) {
        <coach-icon [icon]="i.icon!" [size]="i.iconSize ?? 's'" />
      }
      <span class="title truncate" [typography]="typography().type" [variant]="typography().variant">
        {{ title().i18n ? t(title().value!) : title().value }}
      </span>
    </div>
    @if (i.subtitle) {
      <span class="subtitle truncate" [typography]="subTypography().type" [variant]="subTypography().variant">
        {{ subtitle().i18n ? t(subtitle().value!) : subtitle().value }}
      </span>
    }
  </div>
</ng-template>
