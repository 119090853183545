import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable, tap } from 'rxjs';

// Example of a feature object from user profile
export type FeatureResponse = {
  polling: boolean;
  drills: boolean;
  admin: boolean;
  liveView: boolean;
  swingCapture: boolean;
};

export type FeatureKey = {
  [key in keyof FeatureResponse]: key;
}[keyof FeatureResponse];

@Injectable({ providedIn: 'root' })
export class FeatureService {
  #http = inject(HttpClient);
  features = signal<Partial<Record<FeatureKey, boolean>>>({});

  initializeAvailableFeatures(): Observable<FeatureResponse> {
    return this.#http.get<FeatureResponse>('/assets/mock/features.json').pipe(
      tap(features => {
        this.features.set(features);
      })
    );
  }

  toggleFeature(feature: FeatureKey): void {
    const currentFeatures = this.features();
    this.features.set({ ...currentFeatures, [feature]: !currentFeatures[feature] });
  }

  isEnabled(feature: FeatureKey): boolean {
    return this.features()[feature] ?? false;
  }
}
