<coach-box class="box" *transloco="let t" [routerLink]="navigation()">
  <div class="header">
    <coach-icon class="icon" [class]="status()" [icon]="icon()" />
    <span typography="overline" class="status">{{ title().i18n ? t(title().value) : title().value }}</span>
  </div>
  <div class="progress">
    <span typography="title">
      @if (loading()) {
        <coach-circular-spinner />
      } @else {
        {{ progress() }}
      }
    </span>
  </div>
</coach-box>
