<button
  class="opener"
  [tabIndex]="hasButtonChild() ? -1 : undefined"
  [class.inactive]="hasButtonChild()"
  (click)="toggle(!isOpen())"
  (clickOutside)="toggle(false)">
  <ng-content />
  @if (isOpen()) {
    @if (menuItems()) {
      <coach-click-menu [items]="menuItems()!" [config]="config()" />
    }
    @if (groupedMenuItems()) {
      <coach-grouped-click-menu [items]="groupedMenuItems()!" [config]="config()" />
    }
  }
</button>
