import { NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Typography, TypographyDirective } from '@shared/directives/typography.directive';
import { i18nAttribute } from '@shared/helpers';
import { AvatarComponent } from '@shared/ui/avatar/avatar.component';
import { IconComponent } from '@shared/ui/icon/icon.component';
import { ClickMenuItem } from '../click-menu.types';

@Component({
  selector: 'coach-click-menu-item',
  imports: [IconComponent, TranslocoDirective, AvatarComponent, NgTemplateOutlet, TypographyDirective],
  templateUrl: './click-menu-item.component.html',
  styleUrl: './click-menu-item.component.scss',
  host: { '[class.dividers]': 'dividers', '[class]': '[item().type, item().class]' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickMenuItemComponent {
  item = input.required<ClickMenuItem>();
  dividers = input(false, { transform: booleanAttribute });
  class = input<string>();

  title = computed(() => i18nAttribute(this.item().title));
  subtitle = computed(() => i18nAttribute(this.item().subtitle));

  typography = computed<Typography>(() => {
    const type = this.item().type === 'info' ? 'overline' : 'body';

    return { type, variant: '1' };
  });

  subTypography = computed<Typography>(() => {
    const type = this.item().type === 'info' ? 'subtitle' : 'caption';
    const variant = type === 'subtitle' ? '2' : '1';

    return { type, variant };
  });
}
