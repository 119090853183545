import { inject, Injectable, linkedSignal, signal } from '@angular/core';
import { rxResource, takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlayerActive } from '@shared/types/user.types';
import { interval } from 'rxjs';
import { ApiService } from './api.service';
import { FeatureService } from './feature.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveService {
  #api = inject(ApiService);
  #feature = inject(FeatureService);

  #isPollingEnsabled = signal(this.#feature.isEnabled('polling'));

  // TODO: Nag the backenders to give us websockets for this
  // TODO: Set to true if/when we want to poll for active users
  polling = linkedSignal<boolean>(() => this.#isPollingEnsabled());

  actives = rxResource({ loader: () => this.#api.get<PlayerActive[]>('active-users') });

  timer =
    this.#isPollingEnsabled() ?
      interval(10000)
        .pipe(takeUntilDestroyed())
        .subscribe(() => {
          if (this.polling()) {
            this.actives.reload();
          }
        })
    : null;

  togglePolling(force?: boolean) {
    this.polling.update(polling => force ?? !polling);
  }
}
