import { Directive, HostBinding, OnInit, input } from '@angular/core';
import { AnimationDirection } from '@shared/types/generic.types';

type StaggeredAnimationDirectiveInput = {
  index: number;
  length?: number;
  direction?: AnimationDirection;
  delay?: number;
};

@Directive({
  selector: '[staggeredAnimation]',
})
export class StaggeredAnimationDirective implements OnInit {
  input = input<StaggeredAnimationDirectiveInput>(
    { direction: 'from-top', index: 0, length: 0 },
    { alias: 'staggeredAnimation' }
  );

  @HostBinding('class.staggered')
  get staggered(): boolean {
    return !!this.input();
  }

  @HostBinding('style.animation-delay')
  get delay(): string {
    return (
      (this.input().direction === 'from-bottom' ?
        (this.input().length ?? 0) - this.input().index
      : this.input().index) *
        (this.input().delay ?? 50) +
      'ms'
    );
  }

  ngOnInit() {
    if (this.input().direction === 'from-bottom' && !this.input().length) {
      throw new Error("StaggeredAnimationDirective: length is required when direction is 'from-bottom'");
    }
  }
}
