<div class="menu" [class]="config().anchorPoint" *transloco="let t">
  <div class="menu-container">
    @for (group of groups(); track group.id; let i = $index) {
      <div
        class="menu-group"
        [staggeredAnimation]="{ direction: config().animation, index: i, length: items().length }">
        @if (group.title.value) {
          <div typography="overline" class="menu-header">
            {{ group.title.i18n ? t(group.title.value) : group.title.value }}
          </div>
        }
        @for (item of group.items; track item.id) {
          <coach-click-menu-item [item]="item" [dividers]="config().dividers" />
        }
      </div>
    }
  </div>
</div>
