import { ViewportRuler } from '@angular/cdk/scrolling';
import { inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';

const BREAKPOINT = {
  MOBILE: 450,
  TABLET: 900,
};

@Injectable({ providedIn: 'root' })
export class DeviceService {
  #viewportRuler = inject(ViewportRuler);

  resize$ = new Subject<void>();

  #isMobile = signal<boolean>(false);
  #isTablet = signal<boolean>(false);
  #isHandheld = signal<boolean>(false);
  #isDesktop = signal<boolean>(false);
  isMobile = this.#isMobile.asReadonly();
  isTablet = this.#isTablet.asReadonly();
  isHandheld = this.#isHandheld.asReadonly();
  isDesktop = this.#isDesktop.asReadonly();

  constructor() {
    this.#onResize();
    this.#viewportRuler
      .change(200)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.#onResize());
  }

  #onResize() {
    this.#isTablet.set(window.innerWidth <= BREAKPOINT.TABLET);
    this.#isMobile.set(window.innerWidth <= BREAKPOINT.MOBILE);
    this.#isHandheld.set(this.#isTablet() || this.#isMobile());
    this.#isDesktop.set(!this.#isHandheld());
    this.resize$.next();
  }
}
