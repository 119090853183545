import { inject, Injectable, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { PlayerOverview } from '@shared/types/user.types';
import { map } from 'rxjs';
import { ApiService } from './api.service';

type HighlightedPlayer = { player: PlayerOverview | undefined; navigation: string | string[] | null } | null;

@Injectable({
  providedIn: 'root',
})
export class HighlightService {
  #api = inject(ApiService);

  #playerId = signal<string | undefined>(undefined);

  showHighlight = signal(false);

  highlight = rxResource<HighlightedPlayer, string | undefined>({
    request: this.#playerId,
    loader: ({ request: id }) =>
      this.#api
        .get<PlayerOverview>(`players/${id}`)
        .pipe(
          map(player => ({ player, navigation: player?.user_id ? ['/players', player.user_id, 'session'] : null }))
        ),
  });

  highlightPlayer(id?: string) {
    if (!id) {
      this.showHighlight.set(false);
    } else {
      this.showHighlight.set(true);
      this.#playerId.set(id);
    }
  }
}
