<div class="menu" [class]="config().anchorPoint">
  <div class="menu-container">
    @for (item of items(); track item.id; let i = $index) {
      <coach-click-menu-item
        [item]="item"
        [staggeredAnimation]="{ direction: config().animation, index: i, length: items().length }"
        [dividers]="config().dividers" />
    }
  </div>
</div>
