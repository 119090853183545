import { computed, inject, Injectable } from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { filterCollectionOnQuery } from '@shared/helpers';
import { PlayerBase, PlayerInvite, PlayerList } from '@shared/types/user.types';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ActiveService } from './active.service';
import { ApiService } from './api.service';

type PlayerWithSession = PlayerBase & { current_session: string | undefined };

@Injectable({
  providedIn: 'root',
})
export class CoachService {
  #api = inject(ApiService);
  #actives = inject(ActiveService);

  #playerListQuery = new BehaviorSubject('');
  #playerListQuery$ = this.#playerListQuery.asObservable().pipe(debounceTime(250), distinctUntilChanged());
  #debouncedPlayerListQuery = toSignal(this.#playerListQuery$);

  players = rxResource({ loader: () => this.#api.get<PlayerBase[]>('players') });
  invites = rxResource({ loader: () => this.#api.get<PlayerInvite[]>(`users/invites`) });

  playerList = computed<PlayerList>(() => {
    const query = this.#debouncedPlayerListQuery() || '';
    const players = this.players.value() || [];
    const invites = this.invites.value() || [];
    const actives = this.#actives.actives.value() || [];

    const activeSessions: Record<string, string> = {};
    actives.forEach(active => (activeSessions[active.user_id] = active.session_id));
    const playersWithSesssions: PlayerWithSession[] = players.map(player => ({
      ...player,
      current_session: activeSessions[player.user_id],
    }));

    return {
      linked: this.sortActivePlayers(filterCollectionOnQuery(playersWithSesssions, 'name', query)),
      invited: filterCollectionOnQuery(invites, 'name', query),
    };
  });

  filterPlayerList(query: string): void {
    this.#playerListQuery.next(query);
  }

  refreshPlayers(): void {
    this.players.reload();
  }

  refreshInvites(): void {
    this.invites.reload();
  }

  sortActivePlayers(players: PlayerWithSession[]): PlayerWithSession[] {
    return players.sort((a, b) => {
      if (a.current_session && !b.current_session) return -1;
      if (!a.current_session && b.current_session) return 1;

      if (a.last_activity && b.last_activity) {
        const dateA = new Date(a.last_activity).getTime();
        const dateB = new Date(b.last_activity).getTime();
        if (dateA !== dateB) {
          return dateB - dateA;
        }
      } else if (a.last_activity && !b.last_activity) {
        return -1;
      } else if (!a.last_activity && b.last_activity) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });
  }
}
