import { ChangeDetectionStrategy, Component, OnInit, contentChild, input, output, signal } from '@angular/core';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { ButtonComponent } from '../button/button.component';
import { ClickMenuConfiguration, ClickMenuItem, ClickMenuItemGroup } from '../click-menu/click-menu.types';
import { ClickMenuComponent } from '../click-menu/click-menu/click-menu.component';
import { GroupedClickMenuComponent } from '../click-menu/grouped-click-menu/grouped-click-menu.component';

@Component({
  selector: 'coach-dropdown',
  imports: [ClickMenuComponent, GroupedClickMenuComponent, ClickOutsideDirective],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit {
  menuItems = input<ClickMenuItem[]>();
  groupedMenuItems = input<ClickMenuItemGroup[]>();
  config = input<ClickMenuConfiguration>({});
  closed = output<boolean>();

  hasButtonChild = contentChild(ButtonComponent);
  isOpen = signal(false);

  toggle(state: boolean) {
    if (!(this.menuItems()?.length || this.groupedMenuItems()?.length)) {
      this.isOpen.set(false);
    } else {
      this.isOpen.set(state);
      if (!state) {
        this.closed.emit(true);
      }
    }
  }

  ngOnInit() {
    if (this.menuItems() && this.groupedMenuItems()) {
      throw new Error('DropdownComponent: Pick either menuItems or groupedMenuItems, not both.');
    }
  }
}
