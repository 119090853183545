import { Point } from '@shared/types/generic.types';
import { Metric, MetricCalculation } from '@shared/types/metric.types';
import { Weather } from '@shared/ui/weather/weather.types';

export type Trace = number[][];

type ModeValues = [
  'closest_to_pin',
  'custom_activity',
  'launch_monitor',
  'pga_show_game_assessment',
  'player_assessment',
  't30_lite',
  'whats_in_my_bag',
];

export type Mode = ModeValues[number];

export const ModeMap: Record<Mode, { i18n: string }> = {
  closest_to_pin: { i18n: 'sessions.mode.closestToPin' },
  custom_activity: { i18n: 'sessions.mode.customActivity' },
  launch_monitor: { i18n: 'sessions.mode.launchMonitor' },
  pga_show_game_assessment: { i18n: 'sessions.mode.pgaShowGameAssessment' },
  player_assessment: { i18n: 'sessions.mode.playerAssessment' },
  t30_lite: { i18n: 'sessions.mode.t30Lite' },
  whats_in_my_bag: { i18n: 'sessions.mode.whatsInMyBag' },
};

export type SessionResponse = {
  game_mode: string;
  sessions: Session[];
  total_count: number;
  user_uuid: string;
};

export type Session = {
  activity_id: string;
  activity_results: ActivityResults;
  bay_session_id: string;
  duration_minutes: number;
  game_mode: Mode;
  id: string;
  longest_shot: number;
  number_of_shots: number;
  performance_hcp: number;
  range_id: string;
  range_name: string;
  report_version: number;
  status: 'active' | 'finished' | 'timed_out';
  timestamp_end: string;
  timestamp_start: string;
  user_id: string;
  weather?: Weather;
};

type ActivityResults = {
  activity_id: string;
  id: string;
  result: string;
  session_id: string;
  step_results: StepResult[];
  timestamp: string;
};

type StepResult = {
  completion_criteria_met: boolean;
  shots: SimpleShot[];
};

type SimpleShot = {
  shot_index: number;
  shot_requirements_met: boolean;
};

export type Shot = {
  id: string;
  user_id: string;
  session_id: string;
  shot_number: number;
  has_launch_monitor_stats: boolean;
  activity_requirements_met?: boolean;
  ball_back_spin?: number;
  ball_horizontal_launch_angle?: number;
  ball_launch_angle?: number;
  ball_side_spin?: number;
  ball_speed?: number;
  ball_spin_axis?: number;
  ball_spin?: number;
  carry?: number;
  chart_coordinates: {
    approach_accuracy?: Point;
    dispersion?: Point;
    tee_dispersion?: Point;
    warmup_dispersion?: Point;
  };
  club_category?: string;
  club_closure_rate?: number;
  club_f_angle?: number;
  club_face_angle?: number;
  club_face_to_path?: number;
  club_head_speed?: number;
  club_horizontal_path_angle?: number;
  club_impact_location?: Point;
  club_lie?: number;
  club_loft?: number;
  club_name?: string;
  club_smash_factor?: number;
  club_vertical_path_angle?: number;
  club?: string;
  curve?: number;
  flat_carry?: number;
  game_shot_id?: number;
  hang_time?: number;
  height?: number;
  hole_idx?: number | null;
  landing_angle?: number;
  launch_angle?: number;
  performance_hcp?: number;
  prox_to_hole?: number;
  resulting_lie?: string;
  right_hand_system?: boolean;
  shot_distance?: number;
  shot_type?: string;
  strokes_gained?: number;
  timestamp: string;
  total_distance?: number;
  trace?: Trace;
  webster_id?: string;
};

export type EnrichedShot = Shot & {
  visible: boolean;
};

export type ShotGroup = {
  type: string;
  category?: string;
  name?: string;
  accent: string;
  shots: EnrichedShot[];
  numberOfVisibleShots: number;
  coordinates?: (Point & { shot?: string | number; shotIndex: number; groupIndex: number })[];
  stats: {
    [key in Metric]?: MetricCalculation;
  };
};
