import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { TypographyDirective } from '@shared/directives/typography.directive';
import { i18nAttribute } from '@shared/helpers';
import { Navigation } from '@shared/types/generic.types';
import { IconComponent, IconName } from '@shared/ui/icon/icon.component';
import { BoxComponent } from '../box/box.component';
import { CircularSpinnerComponent } from '../circular-spinner/circular-spinner.component';

export type ProgressCard = {
  title: string;
  state?: string;
  progress?: number | string;
  loading?: boolean;
  icon: IconName;
  navigation?: Navigation;
};

@Component({
  selector: 'coach-progress-card',
  imports: [IconComponent, TranslocoDirective, BoxComponent, TypographyDirective, RouterLink, CircularSpinnerComponent],
  templateUrl: './progress-card.component.html',
  styleUrl: './progress-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressCardComponent {
  title = input.required({ transform: i18nAttribute });
  status = input<string>();
  loading = input(false, { transform: booleanAttribute });
  progress = input<string, number | string | undefined>('0', {
    transform: p => {
      if (!p || p === 'undefined') return '0';
      return typeof p === 'string' ? p : `${(p * 100).toFixed(1)}%`;
    },
  });
  navigation = input<Navigation>();
  icon = input<IconName>('check_circle');
}
